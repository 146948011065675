import React from "react";
import "./projects-page.css";
import konkurs from "../../assets/2024-25/Gorusica_Stipendije_2024_25_Konkurs.pdf";

export default function ProjectsPage() {
  return (
    <div id="ProjectsPage">
      <div className="projects-page-wrapper">
        <div className="projects-page-left">
          <p class="projects-page-heading">Stipendije</p>
          <span class="projects-page-description-text">
            <a
              href={konkurs}
              download="Gorusica_Stipendije_2024_25_Konkurs.pdf"
            >
              Preuzmi konkurs - Stipendije (školarci i studenti) 2024/25.
            </a>
          </span>
          <br></br>
          <hr style={{ width: "80%" }} />
          <br></br>
          <div class="projects-page-gallery">
            {/*<a href="https://tally.so#tally-open=wAxvWy&tally-layout=modal&tally-emoji-animation=none">*/}
            <div class="gallery-box gallery-box-first">
              <div class="gallery-box-content-heading">
                <p>Stipendije (Školarci) 2024/25.</p>
                <span>Rok za prijavu: Istekao</span>
                <br></br>
              </div>
              <div class="gallery-box-content">
                <p>KONKURS JE ZATVOREN</p>
              </div>
            </div>
            {/*</a>*/}
            {/*<a href="https://tally.so#tally-open=mBZ78e&tally-layout=modal&tally-emoji-animation=none">*/}
            <div class="gallery-box gallery-box-second">
              <div class="gallery-box-content-heading">
                <p>Stipendije (Studenti) 2024/25.</p>
                <span>Rok za prijavu: Istekao</span>
                <br></br>
              </div>
              <div class="gallery-box-content">
                <p>KONKURS JE ZATVOREN</p>
              </div>
            </div>
            {/*</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./navbar.css";
import { Link } from "react-scroll";

export default function Navbar(props) {
  return (
    <div id="Navbar">
      <nav>
        <input id="nav-toggle" type="checkbox" />
        <div className="logo"></div>
        <ul className="links">
          <li>
            <Link to="front-page">Početna</Link>
          </li>
          <li>
            <Link to="AboutPage">O fondaciji</Link>
          </li>
          <li>
            <Link to="ProjectsPage">Stipendije</Link>
          </li>
          <li>
            <Link to="footer">Kontakt</Link>
          </li>
        </ul>
        <label for="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>
    </div>
  );
}

import "./App.css";
import FrontPage from "./components/FrontPage/FrontPage";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import AboutPage from "./components/AboutPage/AboutPage";
import ProjectsPage from "./components/ProjectsPage/ProjectsPage";

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <FrontPage></FrontPage>
      <AboutPage></AboutPage>
      <ProjectsPage></ProjectsPage>
      <Footer></Footer>
    </div>
  );
}

export default App;

import React from "react";
import "./about-page.css";

export default function AboutPage() {
  return (
    <div id="AboutPage">
      <div className="about-page-wrapper">
        <div className="about-page-left">
          <p class="about-page-heading">O fondaciji</p>
          <hr style={{ width: "80%" }} />
          <br />
          <p class="about-page-description-text">
            Udruženje/fondacija Gorušica je nastala iz plemenite ideje
            nekolicine ljudi iz različitih krajeva lijepe nam Bosne i
            Hercegovine, a nastanjenih u Stuttgart-u. Primarni cilj je
            stipendiranje jetima/siročadi i djece bez jednog skrbnika (iz
            različitih dijelova BiH), podrška i briga o drugim ugroženim
            kategorijama te osobama u potrebi.
            <br />
            <br />
            Zašto ime <b>"Gorušica"</b>?
            <br />
            <br />
            Gorušica je izuzetno sitno sjeme biljke koje se spominje u časnom
            Kur'anu i obećanju Gospodara s.w.t. da ćemo svi vidjeti dobro koje
            uradimo, pa makar ono bilo sitno koliko i sjeme Gorušice. To
            neznatno i sitno sjeme zasadila je grupa entuzijazista osnivajući
            ovu fondaciju i zadajući sebi visoke ciljeve. Danas je VHB Gorušica
            ( Verein für Hilfe in Bosnien) registrovana u sudski registar
            udruženja sa velikim brojem donatora i izgrađenom pozitivnom
            reputacijom. Ljudi koji se brinu o birokratiji fondacije, kao i da
            novac donatora dolazi na prave adrese i u prave ruke, rade
            isključivo volonterski i u ime svog Gospodara odvajajući svoje
            vrijeme i kapacitete u te svrhe. Do danas su od donacija finansirani
            brojni plemeniti projekti u vidu jednokratne finansijske pomoći
            ugroženima, prehrambenim paketima, iftarima, a kao primarno na
            mjesečnom nivou se stipendira nemali broj jetima i djece bez jednog
            ili oba roditelja. Naši projekti se redovno dokumentiraju i
            fotografišu, te dostavljaju na uvid našim donatorima.
            <br />
            <br />
            Fondaciju možete podržati i Vi postajući njen donator.
            <br />
            <br />
            <i>
              Ajet: "A onaj ko bude uradio koliko trun dobra, vidjet će ga, a
              onaj ko bude uradio koliko trun zla, vidjet će ga"(Sura Ez-Zilzal
              6-8).
            </i>
          </p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./front-page.css";

/*#00afaa - svijetlo zelena */
/*#003764 - tamno plava */
/*#007167 - tamno zelena */
export default function FrontPage() {
  return (
    <div className="FrontPage">
      <div id="front-page">
        <div className="front-page-wrapper">
          <div className="front-page-content">
            <p
              className="front-page-heading"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-duration="700"
            >
              <font color="#00afaa">VHB</font> Gorušica
              <br />
              <font color="#00afaa">V</font>erein für{" "}
              <font color="#00afaa">H</font>ilfe in{" "}
              <font color="#00afaa">B</font>osnien
              <hr style={{ width: "100%" }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div id="footer">
      <div className="footer-bottom">
        <div className="footer-contact">
          <div className="footer-left">
            <p className="footer-text-heading">
              VHB Gorušica - Verein für Hilfe in Bosnien
            </p>
            <span className="footer-text">
              Heinrich-Gyr-Str. 25, Esslingen am Neckar 73733
            </span>
            <p className="footer-text">gorusicavhb@gmail.com</p>
            <span className="footer-text">
              <b>IBAN:</b> DE92 6115 0020 0104 2490 35 | <b>BIC:</b> ESSLDE66XXX
            </span>
            <p className="footer-text">
              <b>PayPal:</b> gorusicavhb@gmail.com
            </p>
          </div>
        </div>
        <p className="footer-copyright-text">
          Copyright &copy; <b>VHB Gorušica 2024</b>
        </p>
      </div>
    </div>
  );
}
